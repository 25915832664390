<template>
  <v-main>
    <v-header
      :headerImage="image"
      :displayOverlay="true"
      :overlayCopy="overlayCopy"
    />
    <container type="bg-dark" id="start">
      <title-and-description title="Influencer Marketing">
        <p>
          When sourcing talent we aim to get a mixture of micro and macro
          influencers. Micro influencers can be booked in volumes and research
          shows they drive better commercial results as the user feels more
          connected to them. Macro influencers play an important role in gaining
          mass brand awareness and are essential for new product and service
          launches, as they are seen as broadcasters.
        </p>
      </title-and-description>
      <hr />
      <title-and-description title="Celebrity Endorsement">
        <p>
          Having the endorsement from a celebrity can mean large exposure for
          your brand on an international scale. Working exclusively with talent
          agents in key global markets, Q Communications secures A-list to
          C-list celebrity engagement for its clients, generating international
          column inches and increased social following. From personal
          endorsements to product launches and events, we can handpick the right
          person for you.
        </p>
      </title-and-description>
      <hr />
      <services-list :serviceList="influencerServices" />
    </container>
    <container type="bg-light-image background-cover">
      <v-row>
        <v-col><h1 class="text-center">Some of Our Projects</h1></v-col>
      </v-row>
      <project-grid :filter="['Influencer and Celebrity']" />
      <center>
        <v-btn tile large color="primary" class="my-10" to="/work"
          >View all of our work</v-btn
        >
      </center>
    </container>
  </v-main>
</template>

<script>
import vHeader from "@/layout/includes/Header.vue";
import ProjectGrid from "../components/ProjectGrid.vue";
import ServicesList from "../components/blocks/ServicesList.vue";

export default {
  name: "Influencer",
  components: {
    vHeader,
    ProjectGrid,
    ServicesList,
  },
  data() {
    return {
      image: require("@/assets/backgrounds/influencer-08.jpg"),
      overlayCopy: [
        {
          copy: "True Influence is about",
          size: "40",
        },
        {
          copy: "leveraging authenticity",
          size: "40",
        },
      ],
      influencerServices: [
        "Influencer Seeding",
        "Collaborations",
        "Gifting",
        "Reviews",
        "Brand Ambassadors",
        "Paid and Non-paid Campaigns",
      ],
    };
  },
};
</script>
